<template>
  <!-- 系统管理 -->
  <div class="system">
    <div class="usertop">
      <p>系统管理</p>
    </div>
    <div class="usermain">
      <div class="modifmess">
        <div class="usename">
          <p>
            企业名称：<span>{{ enterprise }}</span>
          </p>
          <p @click="xiugai">修改</p>
        </div>
        <div class="usename">
          <p>账号权限：企业用户</p>
        </div>
        <div class="usename">
          <p>
            账号：<span>{{ name }}</span>
          </p>
        </div>
        <div class="usename">
          <p>密码：<span>********</span></p>
        </div>
        <div class="usename">
          <p>
            联系人1:
            <span>{{ user_name }}</span>
          </p>
        </div>
        <div class="usename">
          <p>
            手机号：
            <span>{{ phone }}</span>
          </p>
        </div>
        <div class="usename">
          <p>
            联系人2:
            <span>{{ user_names }}</span>
          </p>
        </div>
        <div class="usename">
          <p>
            手机号：
            <span>{{ phones }}</span>
          </p>
        </div>
      </div>
    </div>
    <el-dialog
      title="修改用户信息"
      :visible.sync="dialogVisible1"
      width="600px"
      :close-on-click-modal="false"
    >
      <div class="userboxq">
        <span>请输入企业名称：</span>
        <el-input
          v-model="newqi"
          placeholder="请输入企业名称"
          style="margin-top: 15px"
        ></el-input>
        <P class="changemsg" v-if="msg"
          ><span v-if="msg.enterprise">{{ msg.enterprise[0] }}</span></P
        >
      </div>

      <div class="userboxq">
        <span>请输入原密码：</span>
        <el-input
          v-model="oldpass"
          placeholder="请输入原密码"
          type="password"
          style="margin-top: 15px"
        ></el-input>
        <P class="changemsg" v-if="warning.old">
          <span>{{ warning.old }}</span>
        </P>
      </div>

      <div class="userboxq">
        <span>请输入新密码：</span>
        <el-input
          v-model="newpass"
          placeholder="请输入新密码"
          type="password"
          style="margin-top: 15px"
        ></el-input>
        <P class="changemsg" v-if="msg"
          ><span v-if="msg.password">{{ msg.password[0] }}</span></P
        >
        <P class="changemsg" v-if="warning.pass"
          ><span>{{ warning.pass }}</span></P
        >
      </div>

      <div class="userboxq">
        <span>验证新密码：</span>
        <el-input
          v-model="newpass1"
          placeholder="验证新密码"
          type="password"
          style="margin-top: 15px"
        ></el-input>
        <P class="changemsg" v-if="warning.new1"
          ><span>{{ warning.new1 }}</span></P
        >
      </div>
      <div class="userboxq">
        <span>联系人1：</span>
        <span>设置完成后将启用新号码接受报警短信</span>
        <el-input
          v-model="username1"
          placeholder="请输入联系人姓名"
          style="margin-top: 15px"
        >
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
        <P class="changemsg" v-if="msg"
          ><span v-if="msg.user_name">{{ msg.user_name[0] }}</span></P
        >
        <el-input
          v-model="userphone"
          placeholder="请输入电话号码"
          style="margin-top: 15px"
        >
          <i slot="prefix" class="el-input__icon el-icon-phone-outline"></i>
        </el-input>
        <P class="changemsg" v-if="msg"
          ><span v-if="msg.phone">{{ msg.phone[0] }}</span></P
        >
      </div>
      <div class="userboxq">
        <span>联系人2:</span>
        <span>设置完成后将启用新号码接受报警短信</span>
        <el-input
          v-model="username2"
          placeholder="请输入联系人姓名"
          style="margin-top: 15px"
        >
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
        <P class="changemsg" v-if="msg"
          ><span v-if="msg.user_names">{{ msg.user_names[0] }}</span></P
        >
        <el-input
          v-model="userphone2"
          placeholder="请输入联系人姓名"
          style="margin-top: 15px"
        >
          <i slot="prefix" class="el-input__icon el-icon-phone-outline"></i>
        </el-input>
        <P class="changemsg" v-if="msg"
          ><span v-if="msg.phones">{{ msg.phones[0] }}</span></P
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="save1">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { adminindex, adminupdate } from "../../api/system/update.js";
export default {
  components: {},
  props: {},
  data() {
    return {
      name: "",
      password: "",
      enterprise: "",
      user_name: "",
      phone: "",
      user_names: "",
      phones: "",
      dialogVisible1: false,
      username: "",
      newqi: "",
      newzhang: "",
      newpass: "",
      username1: "",
      userphone: "",
      username2: "",
      userphone2: "",
      alldata: "",
      role: "",
      oldpass: "",
      newpass1: "",
      id: "",
      msg: "",
      warning: {
        old: "",
        pass: "",
        new1: "",
      },
    };
  },
  created() {
    adminindex().then((res) => {
      console.log(res.data[0]);
      var str = res.data[0];
      if (str) {
        this.name = str.account;
        this.password = this.$store.state.password;
        this.enterprise = str.enterprise;
        this.user_name = str.user_name;
        this.user_names = str.user_names;
        this.phone = str.phone;
        this.phones = str.phones;
        this.role = str.role; 
        this.id = str.id;
        this.alldata = str;
      }
    });
  },
  mounted() {
     //console.log(this.$store.state.password);
  },
  methods: {
    save1() {
      this.warning.pass = "";
      this.warning.old = "";
      this.warning.new1 = "";
      this.msg = "";
      if (
        this.oldpass === this.$store.state.password &&
        this.newpass === this.newpass1 &&
        this.newpass !== "" &&
        this.newpass1 !== ""
      ) {
        this.warning.pass = "";
        this.warning.old = "";
        this.warning.new1 = "";
        adminupdate(
          this.role,
          this.id,
          this.newqi,
          this.newzhang,
          this.newpass,
          this.username1,
          this.userphone,
          this.username2,
          this.userphone2
        ).then((res) => {
          console.log(res)
          if (res.msg != "修改成功！") {
            console.log(this.msg);
            this.msg = res.data;
            console.log(this.msg);
          } else {
            this.$store.commit("savepassword", this.newpass);
            console.log(this.$store.state.password);
            adminindex().then((res) => {
              console.log(res.data[0]);
              var str = res.data[0];
              if (str) {
                this.name = str.account;
                this.password = this.$store.state.password;
                this.enterprise = str.enterprise;
                this.user_name = str.user_name;
                this.user_names = str.user_names;
                this.phone = str.phone;
                this.phones = str.phones;
                this.role = str.role;
                this.id = str.id;
                this.alldata = str;
                this.dialogVisible1 = false;
                this.$message({
                  message: "恭喜你，修改成功！",
                  type: "success",
                });
                this.dialogVisible1 = false;
              }
            });
          }
        });
      } else {
        if (this.oldpass === "") {
          this.warning.old = "原先密码不能为空";
        } else if (this.oldpass !== this.$store.state.password) {
          this.warning.old = "原先密码错误";
        }
        if (this.newpass === "") {
          this.warning.pass = "密码不能为空";
        }
        if (this.newpass1 === "") {
          this.warning.new1 = "密码不能为空";
        }
        if(this.newpass.length < 8 && this.newpass.length > 18){
         this.warning.pass = "新密码长度必需在8-18位之间";
        }
        if (this.newpass !== this.newpass1 && this.newpass1 != "") {
          this.warning.new1 = "两次密码不一致";
          this.warning.pass = "两次密码不一致";
        }
      }

       // this.dialogVisible1 = false;
       // console.log(this.newpass.length)
    },
    xiugai() {
      this.dialogVisible1 = true;
      this.newqi = this.alldata.enterprise;
      this.newzhang = this.alldata.account;
      this.newpass = "";
      this.newpass1 = "";
      this.oldpass = "";
      this.username1 = this.alldata.user_name;
      this.userphone = this.alldata.phone;
      this.username2 = this.alldata.user_names;
      this.userphone2 = this.alldata.phones;
      this.msg = "";
      this.warning.pass = "";
      this.warning.old = "";
      this.warning.new1 = "";
    },
  },
};
</script>

<style  lang='less' scoped>
* {
  box-sizing: border-box;
}
.system {
  padding: 20px;
  font-size: 20px;
  .usertop {
    height: 67px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    font-size: 24px;
    margin-bottom: 20px;
  }
  .usermain {
    min-height: 760px;
    background: white;
    padding: 35px;
    padding-bottom: 15px;
    font-size: 22px;
  }
  /deep/.el-dialog__body {
    padding-top: 0px;
  }
  .usename {
    width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      margin-left: 10px;
    }
    p:nth-of-type(2) {
      color: #4483f4;
      font-size: 18px;
      cursor: pointer;
    }
  }
  .userboxq {
    margin-top: 15px;
    span {
      font-size: 20px;
    }
    span:nth-of-type(2) {
      font-size: 16px;
      color: #ff4542;
      margin-bottom: 15px;
    }
    /deep/.el-input__inner {
      font-size: 18px;
    }
  }
  .dialog-footer {
    /deep/.el-button {
      font-size: 22px;
    }
  }
  .changemsg {
    > span {
      font-size: 12px;
    }
    color: red;
    margin: 0px;
  }
  /deep/.el-dialog__title {
    font-size: 24px;
  }
}
</style>
