import request from "../../utils/request"

//修改信息接口
export function adminupdate(role, id, enterprise, account, password, user_name, phone, user_names, phones) {
    const url = `/api/admin/update`;
    return request.post(url, {
        role,
        id,
        enterprise,
        account,
        password,
        user_name,
        phone,
        user_names,
        phones
    });
}

// 查询所有信息的接口
export function adminindex() {
    const url = `/api/admin/index`;
    return request.get(url);
}